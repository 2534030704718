%bannerSize--standard {
  //height: 350px;
  height: 160px;

  @include media-breakpoint-up(sm) { // 576px and up
    height: 350px;
  }
  @include media-breakpoint-up(md) { // 768px and
    height: 400px;
  }
  @include media-breakpoint-up(lg) { // 992px and up
    height: 450px;
  }
  @include media-breakpoint-up(xl) { // 1200px and up
    height: 560px;
  }
  @media (min-width: 1601px) {
    height: 625px;
  }
  @media (min-width: 1901px) {
    height: 715px;
  }
  @media (min-width: 2201px) {
    //height: 700px;
    height: 850px;
  }
}